import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import { Drawer } from "@material-ui/core";
import SidebarNav from "./SidebarNav";
import ApartmentIcon from "@material-ui/icons/Apartment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import GroupIcon from "@material-ui/icons/Group";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocationCityIcon from "@material-ui/icons/LocationCity";
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ForumIcon from '@material-ui/icons/Forum';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { Accessibility, BusinessOutlined, ContactSupport, StoreOutlined } from "@material-ui/icons";

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles(props);

  const pages = [
    {
      title: "Overview",
      href: "/dashboard",
      icon: <ApartmentIcon />,
    },
    
    {
      title: "Users",
      href: "#",
      icon: <GroupIcon />,
      nested: [
        {
          title: "Admin",
          href: "/users/admin",
          icon: <PersonOutlineIcon />,
        },
        {
          title: "Applicants",
          href: "/users/applicants",
          icon: <SupervisedUserCircleIcon />,
        },
        {
          title: "Roles & Permissions",
          href: "/users/roles",
          icon: <Accessibility />,
        },
      ],
    },
    {
      title: 'Referrals',
      href: '/referrals',
      icon: <RecordVoiceOverIcon />,
    },
    {
      title: 'Feedback',
      href: '/feedback',
      icon: <ForumIcon />,
    },
    {
      title: 'FAQs',
      href: '/faqs',
      icon: <ContactSupport />,
    },
    {
      title: "Settings",
      href: "#",
      icon: <SettingsIcon />,
      nested: [
        {
          title: "Loan Providers",
          href: "/providers",
          icon: <MonetizationOnIcon />,
        },
        {
          title: "Loan Documents",
          href: "/documents",
          icon: <BusinessOutlined />,
        },
        // {
        //   title: "Organizations",
        //   href: "/organizations",
        //   icon: <BusinessIcon />,
        // },
        {
          title: "Institutions",
          href: "/institutions",
          icon: <LocationCityIcon />,
        },
        {
          title: "Customers",
          href: "/customers",
          icon: <GroupIcon />,
        },
        {
          title: "Vendors",
          href: "/vendors",
          icon: <GroupIcon />,
        },
        {
          title: "Stores",
          href: "/stores",
          icon: <StoreOutlined />,
        },
        {
          title: "Categories",
          href: "/categories",
          icon: <StoreOutlined />,
        },
        {
          title: "Brands",
          href: "/brands",
          icon: <StoreOutlined />,
        },
        {
          title: "Products",
          href: "/products",
          icon: <StoreOutlined />,
        },
        {
          title: "Banks",
          href: "/banks",
          icon: <AccountBalanceIcon />,
        },
        {
          title: "Partners",
          href: "/partners",
          icon: <GroupWorkIcon />,
        },
      ]
    },
    {
      title: 'Audit Trail',
      href: '/audit',
      icon: <AssessmentIcon />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      classes={{ paper: classes.drawer }}
    >
      <div {...rest} className={classes.root}>
        {/* <Logo logoDark marginBottom="2rem" /> */}
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
