import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import { Button as MuiButton, CircularProgress } from '@material-ui/core'
import { useStyles } from './styles'

const Button = props => {
    const classes = useStyles(props)
    return (
        <>
            <MuiButton
            component={props.linkPage ? Link : undefined }
            to={props.linkPage}
            variant={props.variant ? props.variant : undefined}
            className={classes.button}
            color={props.color}
            onClick={props.onClick}
            size={props.size}
            disabled={props.disabled || props.loading}
            type={props.type}
            padding={props.padding}
            >
                { props.children } 
                {props.loading && 
                    <CircularProgress size={24} className={classes.buttonProgress}/>
                }
            </MuiButton>
        </>
    )
}

Button.propTypes = {
    linkPage: PropTypes.string,
    to: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    loading: PropTypes.bool
}

export default Button
